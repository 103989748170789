import wx from 'weixin-js-sdk'
import {
  wxConfig
} from './gobal'
import fa from "element-ui/src/locale/lang/fa";


const config = async (apiList = ['updateAppMessageShareData','updateTimelineShareData', 'hideAllNonBaseMenuItem', 'showMenuItems', 'scanQRCode']) => {

  let referer = window.location.href.split('#')[0];
  let res = await wxConfig();
  
  wx.config({
    debug: false,
    appId: res.appId,
    timestamp: res.timestamp,
    nonceStr: res.nonceStr,
    signature: res.signature,
    jsApiList: apiList
  })
};



const wshare = (share) => {
  wx.updateAppMessageShareData({ //发送给朋友
    title: share.title,
    desc: share.desc, // 分享描述
    link: share.link,
    imgUrl: share.imgUrl,
    success: function () {},
    cancel: function () {}
  });
  wx.updateTimelineShareData({ //分享至朋友圈
    title: share.title, // 分享标题
    link: share.link, // 分享链接
    imgUrl: share.imgUrl, // 分享图标
    success: function () {},
    cancel: function () {}
  });
  wx.onMenuShareTimeline({
    title: share.title, // 分享标题
    link: share.link, // 分享链接
    imgUrl: share.imgUrl, // 分享图标
    success: function () {
      // 用户点击了分享后执行的回调函数
    }
  });
}

export {
  config,
  wshare
}
