<template>
  <div>
    <div class="navBar" v-show="active!=0||isShowBg">
      <div style="width:50%">
        <img src="../assets/nav/kjLogo.png" alt="快进商店KJ-Store-有快进,更亲近！" title="快进商店KJ-Store-有快进,更亲近！" />
      </div>

      <div class="nav-list">
        <div
          v-for="(item, index) in navList"
          :key="item.name"
          @click="changeTitle(index,item)"
          class="navItem"
        >
          <div>{{ item.name }}</div>
          <div
            class="line"
            :class="{ 'nav-list--active': index === active, }"
          ></div>
          <div class="line" :class="{ 'last-nav-list--active':(index == actIndex)&&(active!=0)&&(actIndex!=active) }"></div>
        </div>
      </div>

      <!-- <div v-for="item in navList" :key="item.name" class="title" @click="checkItem(item)"><div>{{item.name}}</div></div> -->
    </div>
    <div v-show="isShowNav">
      <div class="navBar1" v-show="active==0&&!isShowBg">
        <div style="width:50%">
          <img src="../assets/nav/kjLogo.png" alt="快进商店KJ-Store" title="快进商店KJ-Store" />
        </div>

        <div class="nav-list">
          <div
            v-for="(item, index) in navList"
            :key="item.name"
            @click="changeTitle(index,item)"
            class="navItem"
          >
            <div>{{ item.name }}</div>
            <div
              class="line"
              :class="{ 'nav-list--active': index === active, }"
            ></div>
            <div class="line" ></div>
          </div>
        </div>

        <!-- <div v-for="item in navList" :key="item.name" class="title" @click="checkItem(item)"><div>{{item.name}}</div></div> -->
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  components: {},
  data() {
    return {
      isShowNav: true,
      isShowBg: false,
      oldScrollTop: 0,
      navList: [
        { name: "首页", path: "/home" },
        { name: "门店改造", path: "/kjProduct" },
        { name: "渠道合作", path: "/cooperation" },
        { name: "开放平台", path: "/devplatform" },
        { name: "关于我们", path: "/aboutUs" }
      ],

      active: 0,
      actIndex: 100,
      translateX: 0
    };
  },
  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.scrolling);
  },
  watch: {
    $route(val, old) {
      if (val.path == "/home") {
        this.active = 0;
      } else if (val.path == "/kjProduct") {
        this.active = 1;
      } else if (val.path == "/cooperation") {
        this.active = 2;
      } else if (val.path == "/devplatform") {
        this.active = 3;
      } else if (val.path == "/aboutUs") {
        this.active = 4;
      }
    }
  },
  methods: {
    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;
      if (scrollStep <= 0) {
        this.isShowBg = true;
        this.isShowNav = true;
      } else{
        this.isShowBg = false;
        this.isShowNav = false;
      }
      if(scrollTop==0){

        this.isShowBg = false
        this.isShowNav=true
      }
    },

    /**
     * 点击切换标题
     * @param {number} index 菜单序列(点击的第index个)
     */
    changeTitle(index, item) {
      this.actIndex = this.active;

      this.active = index;
      this.$router.push(item.path);
    }
  }
};
</script>
<style lang="scss" scoped>
@keyframes changeNav {
  from {
    height: 0px;
  }
  to {
    height: 70px;
  }
}
.navBar1 {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
  // animation: changeNav 0.5s 1 alternate;
  // animation-fill-mode: forwards;
  // -webkit-animation-fill-mode: forwards;
  display: flex;
  align-items: center;
  height: 70px;

  font-size: 1.25rem;
  font-family:opposm,sans-serif;

  color: #ffffff;

  img {
    width: 8rem;

    margin-left: 16.25rem;
  }
  .title {
    margin-left: 70px;
    cursor: pointer;
  }
  @keyframes changeLength {
    from {
      width: 0px;
    }
    to {
      width: 100%;
    }
  }
  @keyframes overChange {
    from {
      width: 100%;
    }
    to {
      width: 0px;
    }
  }
  .line {
    display: inline;
    transform: rotate(180deg) !important;

  }
  .nav-list {

    display: flex;

    align-items: center;
  }
  .navItem {
    position: relative;
    cursor: pointer;
  }
  .nav-list > div {
    margin-left: 70px;
  }

  .nav-list .nav-list--active {
    position: absolute;
    top: 30px;
    width: 0px;
    height: 3px;
    margin-top: 0px;
    background-color: #ff3162;
    /*调用动画*/
    animation: changeLength 0.5s 1 alternate;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }
  .nav-list .last-nav-list--active {
    position: absolute;
    top: 30px;
    width: 100%;
    height: 3px;
    margin-top: 5px;
    background-color: #ff3162;
    /*调用动画*/

    animation: overChange 0.5s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }
}
.navBar {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
  background-color: #1f10e0;
  display: flex;
  align-items: center;
  height: 70px;

  font-size: 1.25rem;
  font-family: opposm,sans-serif;

  color: #ffffff;

  img {
   width: 8rem;
    margin-left: 16.25rem;
  }
  .title {
    margin-left: 70px;
    cursor: pointer;
  }
  @keyframes changeLength {
    from {
      width: 0px;
    }
    to {
      width: 100%;
    }
  }
  @keyframes overChange {
    from {
      width: 100%;
    }
    to {
      width: 0px;
    }
  }
  .line {
    display: inline;
    transform: rotate(180deg) !important;
  }
  .nav-list {

    display: flex;

    align-items: center;
  }
  .navItem {
    position: relative;
    cursor: pointer;
  }
  .nav-list > div {
    margin-left: 70px;
  }

  .nav-list .nav-list--active {
    position: absolute;
    top: 30px;
    width: 0px;
    height: 3px;
    margin-top: 0px;
    background-color: #ff3162;
    /*调用动画*/
    animation: changeLength 0.5s 1 alternate;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }
  .nav-list .last-nav-list--active {
    position: absolute;
    top: 30px;
    width: 100%;
    height: 3px;
    margin-top: 5px;
    background-color: #ff3162;
    /*调用动画*/

    animation: overChange 0.5s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }
}
</style>
