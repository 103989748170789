<template>
  <div class="nav-mobile">
    <div class="nav-head">
      <div class="brand">
        <img src="../../assets/mobile/nav/logo.png" alt />
      </div>
      <div class="menu_btn" @click.stop="open">
        <div :class="{'turndown':show,'noturndown':!show&&this.lastshow}"></div>
        <div :class="{'turnout':show,'noturnout':!show&&this.lastshow}"></div>
        <div :class="{'turnup':show,'noturnup':!show&&this.lastshow}"></div>
      </div>
    </div>
    
      <div class="nav-menu" v-if='show' :class="{'menus':show,'nomeuns':!show&&this.lastshow}">
        <div v-for="(item,index) in menu" :key="item.name">
          <div class="menuItem" @click='changeroute(item.path)'>
            <div>{{item.name}}</div>
            <div>
              <img src="../../assets/mobile/nav/arrow.png" alt />
            </div>
          </div>
          <div class="menu-line" v-if="index!=menu.length-1"></div>
        </div>
      </div>
    
  </div>
</template>
<script>
import {mapState} from 'vuex'
import Vue from "vue";
import { Popup } from "vant";


Vue.use(Popup);
export default {
 
  data() {
    return {
     
      menu: [
        { name: "首页",path:"/mHome"},
        { name: "门店改造",path:"/mproduct" },
        { name: "渠道合作",path:"/mCooperation" },
        { name: "开放平台",path:"/mDevplatform" },
        { name: "关于我们",path:"/mAboutUs"}
      ],
      lastshow: false
    };
  },
   watch: {
    show (val) {
    }
  },
  computed: {
   ...mapState([
        'show',
        
    ]),
}, 
created(){
 
},
  methods: {
    open() {
      this.lastshow = this.show;
      this.$store.commit('changeShow',!this.show)
      // this.show = !this.show;
     
    },
    changeroute(item){
      this.$router.push(item)
      this.open()
    }
  }
};
</script>
<style scoped>
.mobile-inner-nav a{
		-webkit-animation-duration: 0.5s;
		animation-duration: 0.5s;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		-webkit-animation-name: returnToNormal;
		animation-name: returnToNormal;
	}
			
	@-webkit-keyframes returnToNormal {
	  0% {
	    opacity: 0;
	    -webkit-transform: translate3d(0, -100%, 0);
	            transform: translate3d(0, -100%, 0);
	  }

	  100% {
	    opacity: 1;
	    -webkit-transform: none;
	            transform: none;
	  }
	}

	@keyframes returnToNormal {
	  0% {
	    opacity: 0;
	    -webkit-transform: translate3d(0, -100%, 0);
	            transform: translate3d(0, -100%, 0);
	  }

	  100% {
	    opacity: 1;
	    -webkit-transform: none;
	            transform: none;
	  }
	}
</style>
<style lang="scss" scoped>
::v-deep .van-overlay{
        position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  background-color: none !important;
}
::v-deep .van-popup{
  position: none;
  background-color: none;
 
    max-height: 100%;
    overflow-y: auto;
    background-color: none;
} 
@keyframes change1 {
  from {
    display: block;
    height: 2px;
    background: #ffffff;
  }
  to {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    // -webkit-transform-origin: 0.25rem 0.3125rem;
    transform-origin: 0rem 0rem;
  }
}
@keyframes change2 {
  from {
    display: block;
    height: 2px;
    background: #ffffff;
  }
  to {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    // -webkit-transform-origin: left;
    transform-origin: 0rem 0rem;
  }
}
@keyframes change3 {
  from {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    // -webkit-transform-origin: 0.25rem 0.3125rem;
    transform-origin: 0rem 0rem;
  }
  to {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    // -webkit-transform-origin: left;
    transform-origin: 0rem 0rem;
  }
}
@keyframes change4 {
  from {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    // -webkit-transform-origin: left;
    transform-origin: 0rem 0rem;
  }
  to {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    // -webkit-transform-origin: left;
    transform-origin: 0rem 0rem;
  }
}
@keyframes change5 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes change6 {
  from {
    
  }
  to {
    width: 100%;
height: 17.25rem;
    
  }
}
@keyframes fadeInDown {
  0% {
    
    -webkit-transform: translate3d(0, -20%, 0);
    -webkit-transform: translate3d(0, -20%, 0);
    transform: translate3d(0, -20%, 0);
    transform: translate3d(0, -20%, 0);
    opacity: 0;
  }
  100% {
    
    -webkit-transform: none;
    transform: none;
    opacity: 1;
    
  }
}
@keyframes fadeInDowns {
  0% {
    height: 17.25rem;
    -webkit-transform: none;
    transform: none;
    opacity: 0.2;
    // color: #1f10e0;
    
  }
  100% {
    
    height: 0;
     -webkit-transform: translate2d(0, -20%, 0);
    -webkit-transform: translate2d(0, -20%, 0);
    transform: translate2d(0, -20%, 0);
    transform: translate2d(0, -20%, 0);
    opacity: 0;
    color: #ffffff;
    
  }
}
.menus {
  animation: fadeInDown 0.5s 1 alternate;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  
}
.nomeuns {
   animation: fadeInDowns 0.5s 1 alternate;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.turndown {
  animation: change1 0.5s 1 alternate;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.noturndown {
  animation: change3 0.5s 1 alternate;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.noturnup {
  animation: change4 0.5s 1 alternate;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.noturnout {
  animation: change5 0.5s 1 alternate;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.turnout {
  opacity: 0;
}
.turnup {
  animation: change2 0.5s 1 alternate;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.nav-mobile {
  background: #1f10e0;

  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
}
.nav-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1f10e0;
  padding: 0.5625rem 22px 0.5625rem 30px;
  box-sizing: border-box;
  height: 55px;
  .menu_btn {
    width: 22px*1.27;
    height: 22px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .menu_btn div {
    display: block;
    height: 2px;
    background: #ffffff;

    // -webkit-transition: all 0.5s;
    // transition: all 0.5s;
  }
  .brand {
    img {
      width: 88px;
    }
  }
}
.nav-menu {
  padding: 1.4rem 30px 2.625rem;
  font-size: 1rem;
  color: #ffffff;
   font-family: opposr, sans-serif;
  font-weight: 400;
}
.menuItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 8px;
  }
}
.menu-line {
  width: 100%;
  margin: 1.3rem 0;
  background-color: #fff;
  height: 1px;
}
</style>
