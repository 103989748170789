import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)
let redirect = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? '/mHome' : '/Home'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

let indexRoute = {};
if (redirect == '/Home'){
  indexRoute = {
    path: '/',
    name: 'home',
    component: () => import( /* webpackChunkName: "about" */ '../views/Home.vue')
  }
}
// else if(redirect == '/mHome'){
//   indexRoute = {
//     path: '/',
//     name: '/mHome',
//     component: () => import( /* webpackChunkName: "about" */ '../views/mobile/mHome.vue')
//   }
// }
else {
  indexRoute = {
    path: '/',
    redirect: redirect
  }
}
const routes = [
  indexRoute,
  {
    path: '/aboutUs',
    name: 'aboutUs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/aboutUs.vue')
  },
  {
    path: '/cooperation',
    name: 'cooperation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/cooperation.vue')
  },
  {
    path: '/kjProduct',
    name: 'kjProduct',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/kjProduct.vue')
  },
  {
    path:"/devplatform",
    name:'devplatform',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/devplatform.vue')
  },
  {
    path: '/home',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/mHome',
    name: '/mHome',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/mobile/mHome.vue')
  },
  {
    path: '/mproduct',
    name: '/mproduct',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/mobile/mproduct.vue')
  },
  {
    path: '/mCooperation',
    name: '/mCooperation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/mobile/mCooperation.vue')
  },
  {
    path: '/mAboutUs',
    name: '/mAboutUs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/mobile/mAboutUs.vue')
  },
  {
    path: '/mDevplatform',
    name: '/mDevplatform',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/mobile/mDevplatform.vue')
  },
  {
    path: '/mDialogConsult',
    name: '/mDialogConsult',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../components/mobile/mDialogConsult.vue')
  },
  {
    path: '/minikj_error',
    name: '/minikj_error',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/mobile/minikj_error.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0  
  if ( to.fullPath.indexOf('?code=') >=0) {
    let code = to.query.code;
    next(`/minikj_error`);
  }
  next()
})
export default router